import React from "react"
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import '../css/qualidade.css'



class messageReceived extends React.Component {

    render() {
        return (
            <>
                <Metatags
                    title="Maxipack"
                    description=""
                    url=""
                    image=""
                    keywords=""
                />
                <Navbar />
                <div className="space-50" style={{ height: "50px" }} />
                <Container>
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="homeText">
                                <h2>Sua mensagem foi enviada. Obrigado.</h2>
                                <h3>Em breve retornaremos!</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <img
                                className="d-block w-100"
                                src={require("../images/Maxi_Pack_email_recebido.jpg")}
                                alt="404"
                            />
                        </Col>
                    </Row>

                    <div className="space-50" style={{ height: "50px" }} />
                </Container>
                <Footer />

            </>
        )
    }
}

export default messageReceived